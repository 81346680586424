
// ARTEX COLOURS

$blue_lighter: #c0eaff;
$blue_bright: #73e3ff;
$blue_light: #71b6f9;
$blue_dark: #077cab;
$blue_darker: #055d7f;
$green_dark: rgba(20,110,55,1);
$green_medium: rgba(65,165,70,1);
$green_bright: rgba(0,195,95,1);
$green_light: rgba(225,245,210,1);
$red: #ff3300;
$artex_green: rgba(65,165,70,1);
$grad1: $green_medium, $green_dark;


// VARIABLES FOR STYLES
$menu-item-hover:        $green_light;
$menu-item-active:       $green_light;
$menu-sub-item-active:   $green_light;

$logo_box_bg: $artex_green;
$left_side_menu_bg: $artex_green;
$side_menu_text: #ffffff;
$side_menu_link: #ffffff;
$side_menu_link_hover: $green_light;
$cool_header: $green_dark;
$site_access: #454545;
$link_default: $green_dark;
$link_default_hover: $green_medium;
$navlink_active:$green_bright;
$border_focus_colour:$green_bright;
$card_header_bgcolour:$green_light;
$slick_arrows:$green_medium;
$access_key:$green_medium;

@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list) !important;
  background: -moz-linear-gradient($direction, $list) !important;
  background: -o-linear-gradient($direction, $list) !important;
  background: linear-gradient($direction, $list) !important;
}

.left-side-menu {
  @include gradient(to bottom, $grad1);
}
