// ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS // ELEMENTS
body {
    color: #757575;
    background-color: #f5f5f5;
    font-size: 1rem;
}
h1, h2, h3, h4, h5, h6 {
    color: #454545;
}
a {
    color: $link_default;
}
// MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU // MENU
.MarketForecastHeight {
    height: 310px;
    margin-bottom: 13px;
}
.logo-box {
    height: $topbar-height;
    width: $leftbar-width;
    float: left;
    background-color: $logo_box_bg;
}
.hideStuff{
    display: none;
    visibility: hidden;
}
.red {
    color: red;
}
.black {
    color: #333333;
}
.green{
    color: #009900;
}
.text-muted {
    color: #878787 !important;
}
.logo {
    color: $primary !important;
    font-size: 32px;
    font-family: 'Rancho', cursive;
    i {
        display: none;
    }
    span {
        span {
            color: $success;
        }
    }
}
.content-page {
    background-image: url($light_honeycomb);
    background-repeat: repeat;
}
.navbar-custom {
    background-color: #eaeaea;
}
.topbar {
    left: 0px;
    position: fixed;
    right: 0;
    top: 0px;
    z-index: 999;
    .topbar-left {
        background: $blue_dark;
        float: left;
        text-align: center;
        height: 73px;
        position: relative;
        width: 250px;
        z-index: 1;
        .logo {
            line-height: 70px;
        }
    }
}
// Sidemenu
.left-side-menu {
    width: $leftbar-width;
    background: $left_side_menu_bg;
    bottom: 0;
    padding: 20px 0;
    font-size:1rem;
    position: fixed;
    transition: all .2s ease-out;
    top: $topbar-height;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    z-index: 99;
}
.side-menu-text {
    color: $side_menu_text;
}
.nav-second-level,
.nav-thrid-level {
    li {
        a {
            padding: 8px 20px;
            color: $side_menu_link;
            display: block;
            position: relative;
            transition: all 0.4s;
            &:focus,
                &:hover {
                color: $menu-item-hover !important;
            }
        }
    }
}
.enlarged .nav-second-level li a, .enlarged .nav-thrid-level li a {
    color: $left_side_menu_bg !important;
}

.nav-second-level,
.nav-third-level {
    li.active {
        >a {
            color: rgba(225,245,210,1) !important;
        }
    }
}

// Sidebar

.login_logout {
    >ul {
        >li {
            >a {
                color: $side_menu_link;
                transition: all 0.4s;
                font-size: 1rem;
                font-weight:400;
                &:hover,
                    &:focus,
                    &:active {
                    color: $side_menu_link_hover !important;
                    text-decoration: none;
                }
                &:visited {
                    color: $side_menu_link;
                }
            }
        }
    }
}

#sidebar-menu {
    >ul {
        >li {
            >a {
                color: $side_menu_link;
                display: block;
                padding: 13px 20px;
                position: relative;
                transition: all 0.4s;
                font-size: 1rem;
                font-weight:500;
                &:hover,
                    &:focus,
                    &:active {
                    color: $side_menu_link_hover !important;
                    text-decoration: none;
                }
                &:visited {
                    color: $side_menu_link;
                }
                >span {
                    vertical-align: middle;
                }
                i {
                    display: inline-block;
                    line-height: 1.0625rem;
                    margin: 0 10px 0 3px;
                    text-align: center;
                    vertical-align: middle;
                    width: 20px;
                }
                .drop-arrow {
                    float: right;
                    i {
                        margin-right: 0;
                    }
                }
            }
            > a.active {
                color: $navlink_active;
                &:hover
                    {
                    color:$side_menu_link_hover;
                }
            }
            > ul {
                padding-left: 40px;
                ul {
                    padding-left: 20px;
                }
            }
        }
    }
    .menu-arrow {
        transition: transform .15s;
        position: absolute;
        right: 20px;
        display: inline-block;
        font-family: 'Material Design Icons';
        text-rendering: auto;
        line-height: 1.5rem;
        font-size: 1.1rem;
        transform: translate(0, 0);
        &:before {
            content: "\F142";
        }
    }
    .badge{
        margin-top: 4px;
    }
    li.active {
        > a {
            > span.menu-arrow {
                transform: rotate(90deg);
            }
        }
    }
    .menu-title {
        padding: 10px 20px;
        letter-spacing: .05em;
        pointer-events: none;
        cursor: default;
        font-size: 0.6875rem;
        text-transform: uppercase;
        color: $red;
        font-weight: $font-weight-medium;
    }
}
// CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE // CORE
/* Footer */
.footer {
    margin: 0px;
    border-top: none;
    bottom: 0px;
    color: #757575;
    text-align:left !important;
    padding: 10px 20px 8px 0px;
    position: absolute;
    /*right: 0px;
    left: 250px;*/
    background-color: #eaeaea;
    .footer-links {
        a {
            color: $link_default;
            margin-left: 1.5rem;
            transition: all .4s;
            &:hover {
                color: $link_default_hover;
            }
            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}
// CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS // CARDS
.card-body {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: 1 1 auto;
    padding: 1rem;
    color: $card-color;
}
.card {
    margin-bottom: $grid-gutter-width;
    box-shadow: $shadow-sm;
    .card-drop {
        font-size: 20px;
        line-height: 0;
        color: inherit;
    }
}
.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;
        &.collapsed {
            i {
                &:before {
                    content: "\F415";
                }
            }
        }
    }
}
// Card title / Card Header
.card-title, .card-header {
    margin-top: 10px;
    background-color: $card_header_bgcolour;
}
//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background: rgba($white, 0.8);
    cursor: progress;
    .card-portlets-loader {
        background-color: $dark;
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: - ($grid-gutter-width / 2);
        margin-top: - ($grid-gutter-width / 2);
    }
}
@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
// Custom card box
.card-box {
    background-color: $white;
    padding: $spacer * 2/3;
    box-shadow: $shadow-sm;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);
    .card-drop {
        color: $gray-600;
        font-size: 20px;
        display: inline-block;
        line-height: 1px;
    }
}
.header-title {
    font-size: 1rem;
    margin: 0 0 7px 0;
}
.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $gray-600;
}
// card desks
@media (min-width: 576px) {
    .card-deck {
        margin-right: -12px;
        margin-left: -12px;
        .card{
            margin-right: 12px;
            margin-left: 12px;
        }
    }
}
// Draggable cards
.card-draggable-placeholder {
    border: 2px dashed rgba($dark, 0.4);
    margin-bottom: 16px;
    background-color: rgba($dark, 0.06);
}// MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC // MISC
.larger {
    font-size: 1.25em;
}
.hidden {
    display: none;
}
.left {
    text-align: left;
}
.right {
    text-align: right;
}
.center {
    text-align: center;
}
.bold {
    font-weight: bold;
}
.nobold {
    font-weight: normal;
}
.medium {
    font-weight:500;
}
/* COLOURS */
.blue_dark {
    color: $blue_dark !important;
}
.blue_light {
    color: $blue_light !important;
}
.green_dark {
    color: $green_dark !important;
}
.green_medium {
    color: $green_medium !important;
}
.green_light {
    color: $green_light !important;
}
.white {
    color: $white !important;
}
.bg-red {
    background-color: $red;
}

.bg-green {
    background-color: $green_dark;
}
.bg-ltgray{
    background-color: #f7f7f7;
}
.bg-device-gray{
    background-color: $device-gray;
}
.bg-device-green{
    background-color: $device-green;
}
.device-image{
    width: 100%;
}
/* BUTTONS / TABS */
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: rgba(180,180,180, 1);
}
.btn-primary {
    background-color: rgba(55, 155, 255, 1);
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: rgba(55, 155, 255, 1);
    border-color: #dee2e6 #dee2e6 #fff;
}
.btn {
    padding: 0.45rem 0.6rem;
}
.btn-lg {
    padding: .5rem 1rem .5rem 1rem;
    font-size: 1.15rem;
    line-height: 1.5;
    border-radius: .2rem;
}
.btn-green, .btn-success {
    color: #fff;
    background-color: $green_medium;
    border: none;
}
.btn-green:hover {
    color: $green_dark;
    text-decoration: none;
    background-color: $green_bright;
}
.btn_site_img {
    margin-left: 20px;
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_site_white.c87b51a2.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_dark;
}
.btn_site_img:hover {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_site_green.c6cea072.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_light;
}
.btn_building_img {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_building_white.d9ab2fe6.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_dark;
}
.btn_building_img:hover {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_building_green.6761ad0a.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_light;
}
.btn_room_img {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_room_white.00f9d9ab.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_dark;
}
.btn_room_img:hover {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_room_green.c8b30d56.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_light;
}
.btn_device_img {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_device_white.d48d5af9.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_dark;
}
.btn_device_img:hover {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_device_green.45bf8fb7.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_light;
}
.btn_autoflex_img {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_autoflex_white.7e75249b.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_dark;
}
.btn_autoflex_img:hover {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_autoflex_green.546ae701.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_light;
}
.btn_prosort_img {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_prosort_white.ef1fc736.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_dark;
}
.btn_prosort_img:hover {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/add_prosort_green.0741d117.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_light;
}


.btn_feeder_img {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/feeder_white.edee4ff8.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_dark;
}
.btn_feeder_img:hover {
    padding-left: 60px;
    padding-top:15px;
    padding-bottom:15px;
    background: url("../images/feeder_green.svg") no-repeat;
    background-position: 10px center;
    background-size: 40px 40px;
    background-color: $green_light;
}
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
    content: '-';
    background-color: $blue_light !important;
}
/* WIDGETS */

.widget_menu_dots {
    //margin: -5px -5px 0px 0px;
    width: 25px;
    height:25px;
    background: url("../images/menu_dots_gray.28006c4d.svg") no-repeat;
    background-size: 25px 25px;
}
.widget_site_img {
    //margin: -5px 0px 0px -5px;
    width: 30px;
    height:30px;
    background: url("../images/add_site_gray.87610a69.svg") no-repeat;
    background-size: 30px 30px;
}
.widget_building_img {
    //margin: -5px 0px 0px -5px;
    width: 30px;
    height:30px;
    background: url("../images/add_building_gray.0d50e23a.svg") no-repeat;
    background-size: 30px 30px;
}
.widget_room_img {
    //margin: -5px 0px 0px -5px;
    width: 30px;
    height:30px;
    background: url("../images/add_room_gray.84add232.svg") no-repeat;
    background-size: 30px 30px;
}
.widget_autoflex_img {
    //margin: -5px 0px 0px -5px;
    width: 30px;
    height:30px;
    background: url("../images/add_autoflex_gray.b5524209.svg") no-repeat;
    background-size: 30px 30px;
}
.widget_prosort_img {
    //margin: -5px 0px 0px -5px;
    width: 30px;
    height:30px;
    background: url("../images/add_prosort_gray.3457685b.svg") no-repeat;
    background-size: 30px 30px;
}


// MARGINS AND PADDING // MARGINS AND PADDING // MARGINS AND PADDING // MARGINS AND PADDING // MARGINS AND PADDING // MARGINS AND PADDING // MARGINS AND PADDING
.mcenter {
    margin-left: auto;
    margin-right: auto;
}

.m-5t {
    margin-top: -5px !important;
}

.m-10t {
    margin-top: -10px !important;
}

.m0a {
    margin: 0px;
}

.m0t {
    margin-top: 0px;
}

.m0b {
    margin-bottom: 0px;
}

.m0l {
    margin-left: 0px !important;
}

.m0r {
    margin-right: 0px;
}

.m5a {
    margin: 5px;
}

.m5t {
    margin-top: 5px;
}

.m5b {
    margin-bottom: 5px;
}

.m5l {
    margin-left: 5px;
}

.m5r {
    margin-right: 5px;
}

.m10a {
    margin: 10px;
}

.m10t {
    margin-top: 10px;
}

.m10b {
    margin-bottom: 10px;
}

.m10l {
    margin-left: 10px;
}

.m10r {
    margin-right: 10px;
}

.m15a {
    margin: 15px;
}

.m15t {
    margin-top: 15px;
}

.m15b {
    margin-bottom: 15px;
}

.m15l {
    margin-left: 15px;
}

.m15r {
    margin-right: 15px;
}

.m20a {
    margin: 20px;
}

.m20t {
    margin-top: 20px;
}

.m20b {
    margin-bottom: 20px;
}

.m20l {
    margin-left: 20px;
}

.m20r {
    margin-right: 20px;
}

.m25a {
    margin: 25px;
}

.m25t {
    margin-top: 25px;
}

.m25b {
    margin-bottom: 25px;
}

.m25l {
    margin-left: 25px;
}

.m25r {
    margin-right: 25px;
}

.p0a {
    padding: 0px;
}

.p0t {
    padding-top: 0px;
}

.p0b {
    padding-bottom: 0px;
}

.p0l {
    padding-left: 0px;
}

.p0r {
    padding-right: 0px;
}

.p5a {
    padding: 5px;
}

.p5t {
    padding-top: 5px;
}

.p5b {
    padding-bottom: 5px;
}

.p5l {
    padding-left: 5px;
}

.p5r {
    padding-right: 5px;
}

.p10a {
    padding: 10px;
}

.p10t {
    padding-top: 10px;
}

.p10b {
    padding-bottom: 10px;
}

.p10l {
    padding-left: 10px;
}

.p10r {
    padding-right: 10px;
}

.p15a {
    padding: 15px;
}

.p15t {
    padding-top: 15px;
}

.p15b {
    padding-bottom: 15px;
}

.p15l {
    padding-left: 15px;
}

.p15r {
    padding-right: 15px;
}

.p20a {
    padding: 20px;
}

.p20t {
    padding-top: 20px;
}

.p20b {
    padding-bottom: 20px;
}

.p20l {
    padding-left: 20px;
}

.p20r {
    padding-right: 20px;
}

.p25a {
    padding: 25px;
}

.p25t {
    padding-top: 25px;
}

.p25b {
    padding-bottom: 25px;
}

.p25l {
    padding-left: 25px;
}

.p25r {
    padding-right: 25px;
}

.p50l {
    padding-left: 50px;
}

.p50r {
    padding-right: 50px;
}

// HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT // HEIGHT
.h10 {
    height: 10px;
}

.h15 {
    height: 15px;
}

.h25 {
    height: 25px;
}

.h26 {
    height: 26px;
}

.h40 {
    height: 40px;
}

.h50 {
    height: 50px;
}

.h75 {
    height: 75px;
}

.h90 {
    height: 90px;
}

.h100 {
    height: 100px;
}

.h125 {
    height: 125px;
}

.h135 {
    height: 135px;
}

.h145 {
    height: 145px;
}

.h150 {
    height: 150px;
}

.h175 {
    height: 175px;
}

.h200 {
    height: 200px;
}

.h225 {
    height: 225px;
}

.h250 {
    height: 250px;
}

.h300 {
    height: 300px;
}

.h325 {
    height: 325px;
}

.h350 {
    height: 350px;
}

.h400 {
    height: 400px;
}

.h425 {
    height: 425px;
}

.h450 {
    height: 450px;
}

.h475 {
    height: 475px;
}

.h500 {
    height: 500px;
}

.h550 {
    height: 550px;
}

.h600 {
    height: 600px;
}

.h650 {
    height: 650px;
}

// WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH // WIDTH
.w10 {
    min-width: 10px;
    max-width: 10px;
}

.w15 {
    min-width: 15px;
    max-width: 15px;
}

.w25 {
    min-width: 25px;
    max-width: 25px;
}

.w30 {
    min-width: 30px;
    max-width: 30px;
}

.w40 {
    min-width: 40px;
    max-width: 40px;
}

.w50 {
    min-width: 50px;
    max-width: 50px;
}

.w60 {
    min-width: 60px;
    max-width: 60px;
}

.w75 {
    min-width: 75px;
    max-width: 75px;
}

.w90 {
    min-width: 90px;
    max-width: 90px;
}

.w100 {
    min-width: 100px;
    max-width: 100px;
}

.w120 {
    min-width: 120px;
    max-width: 120px;
}

.w125 {
    min-width: 125px;
    max-width: 125px;
}

.w140 {
    min-width: 140px;
    max-width: 140px;
}

.w150 {
    min-width: 150px;
    max-width: 150px;
}

.w175 {
    min-width: 175px;
    max-width: 175px;
}

.w200 {
    min-width: 200px;
    max-width: 200px;
}

.w225 {
    min-width: 225px;
    max-width: 225px;
}

.w250 {
    min-width: 250px;
    max-width: 250px;
}

.w275 {
    min-width: 275px;
    max-width: 275px;
}

.w300 {
    min-width: 300px;
    max-width: 300px;
}

.w325 {
    min-width: 325px;
    max-width: 325px;
}

.w350 {
    min-width: 350px;
    max-width: 350px;
}

.w365 {
    min-width: 365px;
    max-width: 365px;
}

.w375 {
    min-width: 375px;
    max-width: 375px;
}

.w400 {
    min-width: 400px;
    max-width: 400px;
}

.dataTables_filter {
    display: none;
}

.w425 {
    min-width: 425px;
    max-width: 425px;
}

.w450 {
    min-width: 450px;
    max-width: 450px;
}

.w475 {
    min-width: 475px;
    max-width: 475px;
}

.w500 {
    min-width: 500px;
    max-width: 500px;
}

.w550 {
    min-width: 550px;
    max-width: 550px;
}

.w575 {
    min-width: 575px;
    max-width: 575px;
}

.w600 {
    min-width: 600px;
    max-width: 600px;
}

.w625 {
    min-width: 625px;
    max-width: 625px;
}

.w650 {
    min-width: 650px;
    max-width: 650px;
}

.w675 {
    min-width: 675px;
    max-width: 675px;
}

.w700 {
    min-width: 700px;
    max-width: 700px;
}

.wp5 {
    width: 5%;
}

.wp10 {
    width: 10%;
}

.wp15 {
    width: 15%;
}

.wp20 {
    width: 20%;
}

.wp25 {
    width: 25%;
}

.wp30 {
    width: 30%;
}

.wp35 {
    width: 35%;
}

.wp40 {
    width: 40%;
}

.wp45 {
    width: 45%;
}

.wp50 {
    width: 50%;
}

.wp55 {
    width: 55%;
}

.wp60 {
    width: 60%;
}

.wp65 {
    width: 65%;
}

.wp70 {
    width: 70%;
}

.wp75 {
    width: 75% !important;
}

.wp80 {
    width: 80%;
}

.wp85 {
    width: 85%;
}

.wp90 {
    width: 90%;
}

.wp95 {
    width: 95%;
}

.wp100 {
    width: 100%;
}



// widget span

div.card-body span.floatRight {
    float: right;
    // color: black;
    margin-left: 10px;
}

.dailyRight{
    margin-left: 10px;
}

.summaryRow{

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

}



//sitemode setup
#singleSite , #multiSite{
    position:fixed;
    opacity:0;
    margin: 10px;
}

.label_item{

    margin: 10px;
}


label {
    cursor: pointer;
}
.fake_link {
    cursor:pointer;
}
.breadcrumb {
    font-size: 16px;
    font-weight: 600;
}
.breadcrumb-item:hover {
    color:#077cab;
    /*-webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);*/
}
.three-dots:hover {
    /* -webkit-transform: scale(1.3);
     -ms-transform: scale(1.3);
     transform: scale(1.3);*/
}
.card-hover-effect:hover{
    /* -webkit-transform: scale(1.1);
     -ms-transform: scale(1.1);
     transform: scale(1.1);*/
    box-shadow: inset 0 0 0 2px #53a7ea;
    cursor:pointer;
}
#addWidget {
    margin: 15px;
}
#dashboardContent {
    margin-top: 25px;
}
.marketSort {
    margin-bottom: 10px;
    margin-top: 10px;
}
.marketSort.alignCenter {
    text-align: center;
}
.light_blue {
    color: #87ceeb !important;
}
#widgetContainer tbody {
    width : 80% ;
}
#widgetContainer table {
    width : 90% ;
    margin-left: auto;
    margin-right: auto;
}
#widgetContainer table {
    background-color: white;
}
table tr:nth-child(even) {
    background-color: white
}
table tr:nth-child(odd) {
    background-color: #f2f2f2
}
#dailySummaryTable div:nth-child(even) {
    background-color: rgba(245, 245, 245, 1)
}
#dailySummaryTable div {
    padding:1px 2px;
}
//draggable cards
.dragbox .card-body {
    -webkit-box-shadow: 0px 0px 2px rgba(0,0,0,.10), 4px 4px 5px rgba(0,0,0,.15);
    box-shadow: 0px 0px 2px rgba(0,0,0,.10), 4px 4px 5px rgba(0,0,0,.15);
    -webkit-transition: all .3s cubic-bezier(.25,.8,.25,1);
    transition: all .3s cubic-bezier(.25,.8,.25,1);
    border-radius: 10px;
    background-color: #fff;
    margin-bottom: 15px;
    border: solid 1px #f5f5f5;
}
.dragbox:hover .card-body {
    -webkit-box-shadow: 0px 0px 2px rgba(0,0,0,.20), 4px 4px 5px rgba(0,0,0,.30);
    box-shadow: 0px 0px 2px rgba(0,0,0,.20), 4px 4px 5px rgba(0,0,0,.30);
}
// .column .dragbox :not(.card-body ,.card-body > * ){
//     float:left;
// }
.column .dragbox {
    float:left;
}
.bigCardText{
    font-size: 25pt;
    margin-bottom: 0 ;
}
#marketFlex{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
#airQualityFlex , #airQualityFlex2 {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.flexBetween{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.flexEnd{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
}
.flex-column {
    flex-direction: column;
}
.flexSide img{
    margin-right: 25px; //changed from 5px
    margin-left: 5px;
    width: 50px;
    margin-bottom: 3px;
    margin-top: 3px;
}
div.centered {
    margin-top: 20px;
    text-align: center;
}
div.centered p {
    margin-left: auto;
    margin-right: auto;
}
//Spinner
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
#airQualityModal .form-control,#airQualityModal .label {
    margin: 10px;
}
#airQualityModal ,#outsideModal ,#singleInputModal , #roomGraphModal {
    .modal-content ,  .modal-content ,  .modal-content  {
        padding: 20px;

    }
}
#airQualityModal , #singleInputModal , #outsiteModal , #roomGraphModal{
    .modal-content {
        margin-bottom: 20px;
    }
}
//Alarms /notifications
#notifications {
    background-color :#88E274;
    // color: orange;
    font-weight: bolder;
}
#alarms {
    background-color: $blue_dark;
    font-weight: bolder;
}
.col-centered{
    float: none;
    margin: 0 auto;
}
#alarmCollapse, #notifCollapse {
    font-size: 0.95rem;
}
#alarmDiv h5{
    color: red;
    margin-top: 10px;
    margin-bottom: 10px;
}
#notificationDiv p{
    margin-top: 10px;
    margin-bottom: 10px;
    color: orange;
}.row .notification {
    border-bottom : 1px solid lightgray;
}
.acknowledgeAlarm {
    background: url('../../assets/images/checkmark-gray.svg') no-repeat;
    width:100px;
    height:100px;
    float:right;
}
.acknowledgeAlarm:hover {
    background: url('../../assets/images/checkmark-green.svg') no-repeat;
}.acknowledged .acknowledgeAlarm{
    background: url('../../assets/images/checkmark-green.svg') no-repeat;
}
.custom-switch label{
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.plusminus {
    float: right;
}
//notification table
.tabHead {
    background-color: #eee;
}
@media only screen and (max-width : 767px) {
    .tabHead {
        display: none;
    }
    //   img #acknowledgeAlarm{
    //     width:50px;
    //     float:left;
    // }
}
.tabHead  * {
    font-weight: bold;
}
//   .tabHead{
//       margin-left: -18px;
//       margin-right:-18px;
//   }
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
//collapsable
//   .colNotif {
//     background-color: #777;
//     color: white;
//     cursor: pointer;
//     padding: 18px;
//     width: 100%;
//     border: none;
//     text-align: left;
//     outline: none;
//     font-size: 15px;
//   }
.colNotif:after {
    float: right;
    margin-left: 5px;

    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f056"
}
.collapsed .colNotif:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f055"
}
.collapsibleContent {
    padding: 0 18px;
    max-height: 0;
    overflow: auto;
    transition: max-height 0.2s ease-out;
    background-color: #f1f1f1;
}
.mesh-loader {
    overflow: hidden;
    height: 50%;
    width: 100%;
}
.mesh-loader .circle {
    width: 40px;
    height: 40px;
    position: absolute;
    background: #03A9F4;
    border-radius: 50%;
    margin: -12px;
    -webkit-animation: mesh 3s ease-in-out infinite;
    animation: mesh 3s ease-in-out infinite -1.5s;
}
.mesh-loader > div .circle:last-child {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.mesh-loader > div {
    position: absolute;
    top: 45%;
    left: 55%;
}
.mesh-loader > div:last-child {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
@-webkit-keyframes mesh {
    0% {
        -webkit-transform-origin: 50% -100%;
        transform-origin: 50% -100%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        -webkit-transform-origin: 50% -100%;
        transform-origin: 50% -100%;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    50.00001% {
        -webkit-transform-origin: 50% 200%;
        transform-origin: 50% 200%;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform-origin: 50% 200%;
        transform-origin: 50% 200%;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes mesh {
    0% {
        -webkit-transform-origin: 50% -100%;
        transform-origin: 50% -100%;
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        -webkit-transform-origin: 50% -100%;
        transform-origin: 50% -100%;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    50.00001% {
        -webkit-transform-origin: 50% 200%;
        transform-origin: 50% 200%;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform-origin: 50% 200%;
        transform-origin: 50% 200%;
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
#overview_datatable {
    overflow-y:scroll !important;
    overflow-x:hidden !important;
    width: 100% !important;
}
// #overview_datatable {
//     // table-layout: fixed;
//     overflow-y:scroll;
//     overflow-x:auto;
//   }
//   #overview_datatable td,
//   #overview_datatable th{
//     width: auto !important;
//     white-space: normal;
//     text-overflow: ellipsis;
//     overflow: hidden;
//   }
.board {
    position: relative;
    margin-left: 1%;
}
.board-column {
    position: absolute;
    left: 0;
    right: 0;
    width: 40%;
    margin: 0 1.5%;
    background: #f0f0f0;
    border-radius: 3px;
    z-index: 1;
}
.board-column.muuri-item-releasing {
    z-index: 2;
}
.board-column.muuri-item-dragging {
    z-index: 3;
    cursor: move;
}
.board-column-header {
    position: relative;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    padding: 0 20px;
    text-align: center;
    background: #333;
    color: #fff;
    border-radius: 3px 3px 0 0;
}
@media (max-width: 600px) {
    .board-column-header {
        text-indent: -1000px;
    }
}
.board-column.todo .board-column-header {
    background: #4A9FF9;
}
.board-column.working .board-column-header {
    background: #f9944a;
}
.board-column.done .board-column-header {
    background: #2ac06d;
}
/* This is the secret sauce,
   always use a wrapper for setting
   the "overflow: scroll/auto" */
.board-column-content-wrapper {
    position: relative;
    max-height: 500px;
    overflow-y: auto;
}
/* Never ever set "overflow: auto/scroll"
   to the muuri element, stuff will break */
.board-column-content {
    position: relative;
    border: 10px solid transparent;
    min-height: 95px;
}
.board-item {
    position: absolute;
    width: 100%;
    margin: 5px 0;
}
.board-item.muuri-item-releasing {
    z-index: 9998;
}
.board-item.muuri-item-dragging {
    z-index: 9999;
    cursor: move;
}
.board-item.muuri-item-hidden {
    z-index: 0;
}
.board-item-content {
    position: relative;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    font-size: 17px;
    cursor: pointer;
    -webkit-box-shadow: 0px 1px 3px 0 rgba(0,0,0,0.2);
    box-shadow: 0px 1px 3px 0 rgba(0,0,0,0.2);
}
@media (max-width: 600px) {
    .board-item-content {
        text-align: center;
    }
}
.iti__flag {
    background-image: url("../../assets/libs/intl-tel-input/flags.png") !important;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("../../assets/libs/intl-tel-input/flags@2x.png") !important;
    }
}
#overview_datatable tbody tr td {
    height: 20px;
}
.configRoom{
    padding: 5% 5% 0 5%;
}
#rooms .card  {
    background-color: transparent;
}
.device {
    padding: 5% 5% 0 5%;
}
.canvasDiv{
    display: none;
}
#overview_datatable tbody tr td{
    padding: .6rem 1.8em !important;
    vertical-align: middle !important;
}
.buildPopTable {
    table-layout: fixed;
    width: 100%;
    overflow: auto;
    padding-left: 5px;
    padding-right:5px;
}
.buildPopTable a{
    color:#1475a3;
}
// .buildPopTable * {
//     word-break: break-all;
// }
///mockups
.mockHead {
    display:flex;
    justify-content: 'space-between';
}
.blueTable th {
    background: $cool_header !important;
    color: #ffffff !important;
}
tfoot{
    background-color: #000000;
}
.buildPopTable {
    width: 100%;
    border-collapse: collapse;
}
/* Zebra striping */
.buildPopTable tr:nth-of-type(odd) {
    background: #eee;
}
.buildPopTable th {
    background: #077cab;
    color: white;
    font-weight: bold;
}
.buildPopTable th, .buildPopTable td, .blueTable th, .blueTable td {
    padding: 6px;
    border: 1px solid #ccc;
    font-size: 0.85em;
    font-size: clamp(0.4em, 1.1vw, 1em);
}
.buildPopTable {
    @media
    only screen and (max-width: 760px),
        (min-device-width: 768px) and (max-device-width: 1024px)  {
        /* Force table to not be like tables anymore */
        table, thead, tbody, th, td, tr {
            display: block;
        }
        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        tr { border: 1px solid #ccc; }
        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
        }
        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
        }
        /*  Label the data */
        td:nth-of-type(1):before { content: "Device"; }
        td:nth-of-type(2):before { content: "Room"; }
        td:nth-of-type(3):before { content: "Population";}
        td:nth-of-type(4):before { content: "ADG"; }
        td:nth-of-type(5):before { content: "Market Sort";}
        td:nth-of-type(6):before { content: "Hits/Pig"; }
        td:nth-of-type(7):before { content: "Default"; }
    }

}

// #basictab4
#basictab4 input:disabled {
    background:#adb5bd6b;
}
// .select2-container .select2-selection--multiple .select2-selection__choice {
//     color: #757575 !important;
//   }
#siteControls , #navBarSite{
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}
.flex_between{
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}
#playControls{
    margin:15px;
}
@media (max-width: 768px) {
    #playControls{
        // display:none;
    }
}
#dash-content.m0l {
    margin-left: 0px !important;
}
#dash-content {
    min-height: 700px;
}
#siteAccess{
    margin-bottom: 50px;
    border: 1px solid #ebe9e9 ;
    border-radius: 10px;
    padding: 10px;

}
#siteAccess h5{
    color:$site_access;
}
#gesLiveCanvas {
    height:200px;
}
#lactLiveCanvas{
    height:270px;
}
.lactHistoryCanvas{
    height: 300px;
}
#homeAccordion .dropdown.float-right {
    display:none;
}
#buttons{
    display:flex;
    justify-content: center;
    flex-flow: row wrap;
}
#buttons > *{
    margin: 10px;
    margin-bottom: 0px;
}
#accessButtons  button{
    margin-right: 10px;
    margin-bottom: 10px;
}
//   .borderedText{
//  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
//   }
//Tooltips - not used currently
.w3-tooltip {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
}
.w3-tooltip .w3-tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: white;
    border: 1px solid grey;
    color: black;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
}
.w3-tooltip:hover .w3-tooltiptext {
    visibility: visible;
    opacity: 1;
}
@media (max-width: 767px) {
    .table-responsive .dropdown-menu {
        position: static !important;
    }
}
@media (min-width: 768px) {
    .table-responsive {
        overflow: visible;
    }
}
//end tooltips
// site dashboard play controls
.site-dash-title {
    margin: 0;
    line-height: 70px;
    padding: 0 20px;
}
.widget-nav {
    display: none ;
    text-align:center;
}
#input_carousel {
    margin-left:20px;
    margin-right:20px;
}
#site_select {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 0px;
    margin-top: 5px;
}
.slick-prev:before {
    color: $slick_arrows !important;
}
.slick-next:before {
    color: $slick_arrows !important;
}
.swine {
    display: none;
}
.cool-header {
    padding:5px;
    text-align: center;
    background: $cool_header;
    color: white;
    margin-bottom:25px;
}
.sub-cool-header {
    padding:5px;
    text-align: center;
    // background: #649aff;
    color: black !important;
    font-size: 30px;
}

#myBtn {
    display: none;
    //position: fixed;
    //bottom: 20px;
    //right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #0068ff47;
    color: white;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 5px;
}
#myBtn:hover {
    background-color: #0068ffab;
}
/* CHRIS */
.table-bordered thead th:focus, .table-striped thead th:focus {
    outline: none !important;
    border: 2px solid $border_focus_colour !important;

}
#reg_form div.form-group label {
    cursor: default;
}
.mdi-equal {
    color:$access_key;
}
.mdi-key, .mdi-key-remove {
    color:$access_key;
}
button > i.mdi-key, button > i.mdi-key-remove {
    color:#fff;
    font-size:1.5em;
}
.nopointer {
    cursor: default;
}
.file_input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
