//
// dropify.scss
//

@font-face {
    font-family: 'dropify';
    src: url("../fonts/dropify.4e8b017a.eot");
    src: url("../fonts/dropify.4e8b017a.eot#iefix") format("embedded-opentype"), url("../fonts/dropify.950ffd1e.woff") format("woff"), url("../fonts/dropify.86cb1ab0.ttf") format("truetype"), url("../fonts/dropify.5a363922.svg#dropify") format("svg");
    font-weight: normal;
    font-style: normal;
}

.dropify-wrapper {
    border: 2px dashed rgba($dark, 0.3);
    background:$white;
    border-radius: 6px;
}